// Also ported to `view/Blocker.php`
export var HTML_ATTRIBUTE_CAPTURE_PREFIX = "consent-original";
export var HTML_ATTRIBUTE_CAPTURE_CLICK_PREFIX = "consent-click-original";
export var HTML_ATTRIBUTE_CAPTURE_SUFFIX = "_";
export var HTML_ATTRIBUTE_BY = "consent-by";
export var HTML_ATTRIBUTE_COOKIE_IDS = "consent-required";
export var HTML_ATTRIBUTE_VISUAL_PARENT = "consent-visual-use-parent";
export var HTML_ATTRIBUTE_INLINE = "consent-inline";
export var HTML_ATTRIBUTE_INLINE_STYLE = "consent-inline-style";
export var HTML_ATTRIBUTE_BLOCKER_ID = "consent-id";
export var HTML_TAG_CONSENT_SCRIPT = "script";
export var HTML_ATTRIBUTE_BLOCKER_CONNECTED = "consent-blocker-connected";